@font-face {
  font-family: 'PlusJakartaSans';
  src: local('PlusJakartaSans'), url(./fonts/PlusJakartaSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-weight: 900;
  src: local('PlusJakartaSans'), url(./fonts/PlusJakartaSans-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-weight: 700;
  src: local('PlusJakartaSans'), url(./fonts/PlusJakartaSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-weight: 300;
  src: local('PlusJakartaSans'), url(./fonts/PlusJakartaSans-Light.woff) format('woff');
}

@font-face {
  font-family: 'PlusJakartaSans';
  font-weight: 100;
  src: local('PlusJakartaSans'), url(./fonts/PlusJakartaSans-ExtraLight.woff) format('woff');
}

html {
  scroll-behavior: smooth;
}

h1 {
  margin: 0;
  font-weight: 900;
}

h2 {
  font-weight: 700;
}

body {
  overflow-x: hidden;
  background-color: black;
  margin: 0;
  font-family: 'PlusJakartaSans', sans-serif;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
