.parent-box {
    perspective: 1000px;
}

.slideOver-enter {
    
}

.slideOver-enter-done {
    transition: all 1.3s ease-out;
    transform: translateZ(0);

}

.slideOver-exit {
    transform: translateZ(-200px);
}

.slideOver-exit-done {
    transform: translateZ(-150px);
    transition: all 1.3s ease-out;
}