.nav {
    position: fixed;
    width: 100%;
    height: 75px;
    top: 0;
    color: white;
    background: black;
    z-index: 100;
}

.nav .links {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav h1 {
    margin-top: 7.5px;
    position: absolute;
    left: 15px;
}

section {
    min-height: 100vh;
    overflow: hidden;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateZ(-250px);
}

.top {
    padding: 0;
    z-index: 1;
}

.top .filter {
    height: 100vh;
    width: 100vw;
    opacity: 0.35;
    position: absolute;
    z-index: 1;
    background-color: black;
}

.top video {
    position: absolute;
    top: -350px;
    left: -20px;
    height: 200vh;
}

.heroBox {
    color: white;
    display: flex;
    padding: 15px;
}

.anchor {
    position: relative;
    top: 0;
}

.linkBar {
    display: flex;
    justify-content: space-evenly;
}

.nav .link {
    font-size: 18px;
    font-weight: 300;
    margin-left: 30px;
    margin-right: 30px;
}

.nav .link a {
    color: white;
    text-decoration: none;
}

.nav .link a:hover {
    color: #999999;
    transition: all 0.2s ease-out;
}

.heroBox {
    position: absolute;
    z-index: 2;
}

.heroBox h1 {
    font-size: 10vw;
}

.heroBox h2 {
    font-size: 5vw;
}

.heroBox h3 {
    font-size: 2.5vw;
}

.heroBox .subtitles {
    font-size: 42px;
    line-height: 12px;
}

.footer {
    display: flex;
    align-items: center;
    color: white;
    height: 75px;
    background-color: black;
}

.showcase {
    color: white;
}

.contentBox {
    width: 50%;
    max-height: 100vh;
    padding: 5px 2px;
}

.contentBox h2{
    font-weight: 900;
    font-size: 3vw;
}

.contentBox p {
    font-size: 2vw;
}